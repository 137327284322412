import {BASE_API_URL} from "./RestService";

export function getNotes(fetchData, byUser) {
    return fetchData(`${BASE_API_URL}/note/all${byUser ? '?byUser=true' : ''}`, {});
}

export function upsertNote(fetchData, note) {
    return fetchData(`${BASE_API_URL}/note`, {
        method: note.id ? 'PUT' : 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(note),
    });
}

export function deleteNote(fetchData, id) {
    return fetchData(`${BASE_API_URL}/note/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}
