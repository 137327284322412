import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from 'reactstrap';
import {useFetchData} from "../http/HttpUtil";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import {getCustomerModel} from "./CustomerModel";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {getCustomer, upsertCustomer} from "../service/CustomerRestService";
import CharacterCounter from "../common/CharacterCounter";
import Spinner from "../common/Spinner";

const CustomerEdit = () => {
    const fetchData = useFetchData();
    const initialFormState = getCustomerModel();
    const [loading, setLoading] = useState(false);

    const location = useLocation();
    const [customer, setCustomer] = useState(initialFormState);
    const navigate = useNavigate();
    const {id} = useParams();
    const {blockClicks, unblockClicks} = useClickBlock();

    useEffect(() => {
        setLoading(true);
        blockClicks();
        if (id !== 'new') {
            getCustomer(fetchData, id)
                .then(data => {
                    setCustomer(data);
                    unblockClicks();
                    setLoading(false);
                });
        } else {
            unblockClicks();
            setLoading(false);
        }
    }, [id, setCustomer, fetchData, blockClicks, unblockClicks]);

    const handleChange = (event) => {
        const {name, value} = event.target;
        if (name === 'birthDate') {
            const birthDate = new Date(value);
            const currentDate = new Date();
            const ageDiff = currentDate.getFullYear() - birthDate.getFullYear();
            const hasBirthdayPassed = (currentDate.getMonth() > birthDate.getMonth()) ||
                (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() >= birthDate.getDate());
            const age = hasBirthdayPassed ? ageDiff : ageDiff - 1;
            setCustomer(prevState => ({...prevState, [name]: value, age: age}));
        } else {
            setCustomer(prevState => ({...prevState, [name]: value}));
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        blockClicks();

        await upsertCustomer(fetchData, customer);

        setCustomer(initialFormState);
        unblockClicks();
        navigate('/customers');
    };

    if (loading) {
        return <Spinner/>;
    }

    return (
        <div>
            <ClickBlockOverlay/>
            <Container>
                <h2>Editar cliente
                    - {!customer.firstName && !customer.firstSurname ? 'NUEVO' : customer.firstName} {customer.firstSurname}</h2>
                <br/>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="firstName">Nombre</Label>
                                <Input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    value={customer.firstName}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="firstSurname">Primer apellido</Label>
                                <Input
                                    type="text"
                                    name="firstSurname"
                                    id="firstSurname"
                                    value={customer.firstSurname}
                                    onChange={handleChange}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="secondSurname">Segundo apellido</Label>
                                <Input
                                    type="text"
                                    name="secondSurname"
                                    id="secondSurname"
                                    value={customer.secondSurname}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="phone">Teléfono</Label>
                                <Input
                                    type="text"
                                    name="phone"
                                    id="phone"
                                    value={customer.phone}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="email">Email</Label>
                                <Input
                                    type="text"
                                    name="email"
                                    id="email"
                                    value={customer.email}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="birthDate">Fecha de nacimiento</Label>
                                <Input
                                    type="date"
                                    name="birthDate"
                                    id="birthDate"
                                    value={customer.birthDate || ''}
                                    max={new Date().toISOString().split("T")[0]}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="idNumber">DNI</Label>
                                <Input
                                    type="text"
                                    name="idNumber"
                                    id="idNumber"
                                    value={customer.idNumber}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={4}>
                            <FormGroup>
                                <Label for="passport">Pasaporte</Label>
                                <Input
                                    type="text"
                                    name="passport"
                                    id="passport"
                                    value={customer.passport}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={2}>
                        </Col>
                        <Col md={2}>
                            <FormGroup>
                                <Label for="age">Edad</Label>
                                <Input
                                    type="number"
                                    name="age"
                                    id="age"
                                    value={customer.age}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="address">Dirección</Label>
                                <Input
                                    type="text"
                                    name="address"
                                    id="address"
                                    value={customer.address}
                                    onChange={handleChange}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={8}>
                            <FormGroup>
                                <Label for="comments">Comentarios</Label>
                                <Input
                                    type="text"
                                    name="comments"
                                    id="comments"
                                    value={customer.comments}
                                    onChange={handleChange}
                                    maxLength={4000}
                                />
                                <CharacterCounter
                                    currentLength={customer.comments?.length}
                                    maxLength={4000}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row hidden={customer.expedients?.length === 0}>
                        <Col md={12}>
                            <FormGroup>
                                <Label for="expedients">Expedientes</Label>
                                <ul>
                                    {customer.expedients?.map(expedient => (
                                        <li key={expedient.id}>{expedient.travelcatId}</li>
                                    ))}
                                </ul>
                            </FormGroup>
                        </Col>
                    </Row>
                    <br/>
                    <br/>
                    <FormGroup>
                        <Button color="success" type="submit">Guardar</Button>{' '}
                        <Button color="danger" tag={Link} to={location.state?.from || "/customers"}>Cancelar</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    );
};

export default CustomerEdit;
