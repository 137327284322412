import React, {createContext, useCallback, useContext, useMemo, useState} from 'react';
import PropTypes from 'prop-types';

const ClickBlockContext = createContext();

export function ClickBlockProvider({children}) {
    const [isBlocked, setIsBlocked] = useState(false);

    const blockClicks = useCallback(() => setIsBlocked(true), []);
    const unblockClicks = useCallback(() => setIsBlocked(false), []);

    const value = useMemo(() => ({isBlocked, blockClicks, unblockClicks}), [isBlocked, blockClicks, unblockClicks]);

    return (
        <ClickBlockContext.Provider value={value}>
            {children}
        </ClickBlockContext.Provider>
    );
}

ClickBlockProvider.propTypes = {
    children: PropTypes.node.isRequired
};

export function useClickBlock() {
    return useContext(ClickBlockContext);
}
