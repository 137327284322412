import {BASE_API_URL} from "./RestService";

export function getSchedules(fetchData, byUser) {
    return fetchData(`${BASE_API_URL}/schedule/all${byUser ? '?byUser=true' : ''}`, {});
}

export function upsertSchedule(fetchData, event) {
    return fetchData(`${BASE_API_URL}/schedule`, {
        method: event.id ? 'PUT' : 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(event),
    });
}

export function deleteSchedule(fetchData, id) {
    return fetchData(`${BASE_API_URL}/schedule/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}
