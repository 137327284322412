import {BASE_API_URL} from "./RestService";

export function getAttachments(fetchData, entity, id) {
    return fetchData(`${BASE_API_URL}/attachment/list/${entity}?id=${id}`, {});
}

export function uploadAttachment(fetchData, entity, id, formData) {
    return fetchData(`${BASE_API_URL}/attachment/upload/${entity}/${id}`, {
        method: 'POST',
        body: formData,
    });
}

export function deleteAttachment(fetchData, path) {
    return fetchData(`${BASE_API_URL}/attachment?path=${path}`, {
        method: 'DELETE'
    });
}

export function downloadAttachment(fetchBlob, path) {
    return fetchBlob(`${BASE_API_URL}/attachment/download?path=${path}`, {
        method: 'GET',
    });
}
