import {Button, ButtonGroup, Table} from "reactstrap";
import Spinner from "../../common/Spinner";
import {calculateNumberOfPeople} from "../../common/Utils";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import PropTypes from "prop-types";

const BudgetDetailCaseOpening = ({budgetDetail, loading, handleOpenAssignUserModal}) => {

    const location = useLocation();

    return (
        <div className="table-container">
            <Table className="mt-4">
                <thead>
                <tr>
                    <th width="15%">Cliente(s)</th>
                    <th width="6%">Nº Personas</th>
                    <th width="8%">Teléfono</th>
                    <th width="8%">Email</th>
                    <th width="9%">Ciudad Origen</th>
                    <th width="14%">Disponibilidad</th>
                    <th width="14%">Viaje de interés</th>
                    <th width="9%">Vía de entrada</th>
                    <th width="6%">Usuario asignado</th>
                    <th width="11%"></th>
                </tr>
                </thead>
                <tbody>
                {loading ? (
                    <tr>
                        <Spinner/>
                    </tr>
                ) : (
                    <>
                        <tr>
                            <td>{budgetDetail.customers}</td>
                            <td>
                                {calculateNumberOfPeople(budgetDetail.people)} (
                                {(budgetDetail.people?.numberOfAdults || 0)}A/
                                {(budgetDetail.people?.kids?.length || 0)}N/
                                {(budgetDetail.people?.numberOfBabies || 0)}B)
                            </td>
                            <td>{budgetDetail.expedient.caseOpening.phone}</td>
                            <td>{budgetDetail.expedient.caseOpening.email}</td>
                            <td>{budgetDetail.expedient.caseOpening.fromCity}</td>
                            <td>{budgetDetail.expedient.caseOpening.availability}</td>
                            <td>{budgetDetail.expedient.caseOpening.tripOfInterest}</td>
                            <td>{budgetDetail.expedient.caseOpening.entrypoint}</td>
                            <td>{budgetDetail.assignedUserId}</td>
                            <td>
                                <ButtonGroup>
                                    <Button size="sm" color="warning" tag={Link}
                                            to={`/budgets/${budgetDetail.id}`}
                                            state={{from: location.pathname}}>Editar</Button>
                                    <Button size="sm" color="success"
                                            onClick={() => handleOpenAssignUserModal(budgetDetail.id, budgetDetail.expedient.travelcatId)}>Asignar
                                        usuario</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                        <tr>
                            <td><strong>Expediente</strong></td>
                            <td colSpan="2"><strong>Usuario que lo ha vendido</strong></td>
                            <td colSpan="2"><strong>Usuario que lo planifica</strong></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colSpan="2">{budgetDetail.expedient.sellerUserId}</td>
                            <td colSpan="2">{budgetDetail.expedient.plannerUserId}</td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colSpan="9"><strong>Comentarios</strong></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td colSpan="9">{budgetDetail.expedient.caseOpening.comments}</td>
                        </tr>
                    </>
                )}
                </tbody>
            </Table>
        </div>
    );
};

BudgetDetailCaseOpening.propTypes = {
    budgetDetail: PropTypes.shape({
        id: PropTypes.any.isRequired,
        customers: PropTypes.any,
        assignedUserId: PropTypes.any,
        expedient: PropTypes.shape({
            caseOpening: PropTypes.shape({
                phone: PropTypes.any,
                email: PropTypes.any,
                fromCity: PropTypes.any,
                availability: PropTypes.any,
                tripOfInterest: PropTypes.any,
                entrypoint: PropTypes.any,
                comments: PropTypes.any,
            }).isRequired,
            sellerUserId: PropTypes.any,
            plannerUserId: PropTypes.any,
            travelcatId: PropTypes.any,
        }).isRequired,
        people: PropTypes.shape({
            numberOfAdults: PropTypes.any,
            kids: PropTypes.any,
            numberOfBabies: PropTypes.any,
        }),
    }).isRequired,
    loading: PropTypes.bool.isRequired,
    handleOpenAssignUserModal: PropTypes.func.isRequired,
};

export default BudgetDetailCaseOpening;
