import React, {useEffect, useState} from 'react';
import {Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";
import {exportBudgetBilling} from '../service/BudgetRestService';

function ExportBillingModal({
                                isOpen,
                                toggle,
                                handleClose,
                                fetchBlob,
                                blockClicks,
                                unblockClicks
                            }) {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    const formatDate = (date) => {
        return date.toLocaleString("sv-SE", {timeZone: "Europe/Madrid"});
    };

    useEffect(() => {
        if (isOpen) {
            const now = new Date();
            const oneMonthAgo = new Date(now);
            oneMonthAgo.setMonth(now.getMonth() - 1);

            setEndDate(formatDate(now).replace(" ", "T").slice(0, 16));
            setStartDate(formatDate(oneMonthAgo).replace(" ", "T").slice(0, 16));
        }
    }, [isOpen]);

    const handleExport = async () => {
        try {
            blockClicks();
            await exportBudgetBilling(fetchBlob, {
                startDateTime: new Date(startDate),
                endDateTime: new Date(endDate)
            }).then(async blob => {
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                const filename = `travelcat_facturacion_${formatDate(new Date()).replace(" ", "_").replace(/[:.]/g, "-")}.csv`;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            });
        } catch (error) {
            console.error('Error exporting file: ', error);
        } finally {
            unblockClicks();
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={handleClose}>
                Exportar facturación - servicios pagados por Travelcat
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>Desde</Label>
                    <Input
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Hasta</Label>
                    <Input
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button
                    color="success"
                    disabled={!startDate || !endDate}
                    onClick={handleExport}
                >
                    Exportar
                </Button>
            </ModalFooter>
        </Modal>
    );
}

ExportBillingModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    toggle: PropTypes.func.isRequired,
    handleClose: PropTypes.func.isRequired,
    fetchBlob: PropTypes.func.isRequired,
    blockClicks: PropTypes.func.isRequired,
    unblockClicks: PropTypes.func.isRequired
};

export default ExportBillingModal;
