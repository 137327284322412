import React, {useCallback, useEffect, useState} from 'react';
import {
    Button,
    ButtonGroup,
    Container,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    Table
} from 'reactstrap';
import {Link, useLocation} from 'react-router-dom';
import '../common/Common.css';
import {useFetchBlob, useFetchData} from "../http/HttpUtil";
import {formatTimestampToDate, formatTimestampToDateTime, isPastDate, isTodayOrTomorrow} from "../common/Utils";
import './BudgetList.css';
import {getBudgetStatus, getTripTypeDictionary} from "./BudgetModel";
import Spinner from "../common/Spinner";
import LinkCustomers from "./LinkCustomers";
import ExpedientModal from "./expedient/ExpedientModal";
import PaginationContainer, {defaultPageParams} from "../common/PaginationContainer";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {assignUser, deleteBudget, duplicateBudget, getBudgets} from "../service/BudgetRestService";
import {assignPlannerUser, assignSellerUser} from "../service/ExpedientRestService";
import AssignUserIdModal from "./AssignUserIdModal";
import ExportBillingModal from "./ExportBudgetBilling";

export const getBudgetStatusStyle = (statusKey) => {
    const statusStyles = {
        PENDING: {backgroundColor: 'white', color: 'black'},
        SENT: {backgroundColor: 'yellow', color: 'black'},
        PENDING_REPLY: {backgroundColor: 'cyan', color: 'black'},
        APPOINTMENT: {backgroundColor: 'purple', color: 'white'},
        CLAIM_IN_PROGRESS: {backgroundColor: '#9dd8e8', color: 'black'},
        CLAIMED: {backgroundColor: 'orange', color: 'black'},
        SOLD: {backgroundColor: 'green', color: 'white'},
        DISCARDED: {backgroundColor: 'red', color: 'white'},
        PENDING_CONTRACT: {backgroundColor: 'magenta', color: 'white'},
        READY_TO_PLAN: {backgroundColor: 'blue', color: 'white'},
        READY_TO_DELIVER: {backgroundColor: 'brown', color: 'white'},
        SCHEDULE_FOR_LATER: {backgroundColor: 'beige', color: 'black'},
        SERVICES_BLOCKED: {backgroundColor: '#00fa9a', color: 'black'},
        ARCHIVED: {backgroundColor: 'grey', color: 'white'},
    };

    return statusStyles[statusKey] || {};
};

const BudgetList = () => {
    const fetchData = useFetchData();
    const fetchBlob = useFetchBlob();
    const location = useLocation();
    const [budgets, setBudgets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [byUser, setByUser] = useState(true);
    const [showLastUpdatedFields, setShowLastUpdatedFields] = useState(false);
    const [pageInfo, setPageInfo] = useState(defaultPageParams);
    const [expandedTravelcatId, setExpandedTravelcatId] = useState(null);
    const initialFilterParams = {
        travelcatId: '',
        title: '',
        tripType: '',
        status: '',
        customers: ''
    }
    const [filterParams, setFilterParams] = useState(initialFilterParams);
    const [groupedBudgets, setGroupedBudgets] = useState({});
    const [openDropdownId, setOpenDropdownId] = useState(null);
    const [isAssignUserModalOpen, setIsAssignUserModalOpen] = useState(false);
    const [inputAssignedUserId, setInputAssignedUserId] = useState('');
    const [assignUserBudgetId, setAssignUserBudgetId] = useState(null);
    const [assignUserExpedientTravelcatId, setAssignUserExpedientTravelcatId] = useState('');
    const [isAssignSellerUserModalOpen, setIsAssignSellerUserModalOpen] = useState(false);
    const [inputAssignedSellerUserId, setInputAssignedSellerUserId] = useState('');
    const [assignSellerUserExpedientId, setAssignSellerUserExpedientId] = useState(null);
    const [assignSellerUserExpedientTravelcatId, setAssignSellerUserExpedientTravelcatId] = useState('');
    const [isAssignPlannerUserModalOpen, setIsAssignPlannerUserModalOpen] = useState(false);
    const [inputAssignedPlannerUserId, setInputAssignedPlannerUserId] = useState('');
    const [assignPlannerUserExpedientId, setAssignPlannerUserExpedientId] = useState(null);
    const [assignPlannerUserExpedientTravelcatId, setAssignPlannerUserExpedientTravelcatId] = useState('');
    const [showLinkCustomers, setShowLinkCustomers] = useState(false);
    const [linkCustomersExpedientId, setLinkCustomersExpedientId] = useState(null);
    const [linkCustomersExpedientTravelcatId, setLinkCustomersExpedientTravelcatId] = useState(null);
    const [linkCustomersExpedientCustomerIds, setLinkCustomersExpedientCustomerIds] = useState(null);
    const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
    const [duplicateModalBudgetId, setDuplicateModalBudgetId] = useState(null);
    const [duplicateModalDefaultAssignedUserId, setDuplicateModalDefaultAssignedUserId] = useState(null);
    const [duplicateModalDefaultExpedientId, setDuplicateModalDefaultExpedientId] = useState(null);
    const [duplicateModalDefaultTravelcatId, setDuplicateModalDefaultTravelcatId] = useState(null);
    const [isExportModalOpen, setIsExportModalOpen] = useState(false);
    const {blockClicks, unblockClicks} = useClickBlock();

    const groupBudgets = useCallback((budgets) => {
        const newGroupedBudgets = {};
        budgets.forEach(budget => {
            if (!newGroupedBudgets[budget.expedient.travelcatId]) {
                newGroupedBudgets[budget.expedient.travelcatId] = [];
            }
            newGroupedBudgets[budget.expedient.travelcatId].push(budget);
        });
        setGroupedBudgets(newGroupedBudgets);
    }, []);

    const fetchBudgets = useCallback((pageNumber, filters) => {
        setLoading(true);
        blockClicks();
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageInfo.pageSize);

        function addCustomQueryParams() {
            for (const key in filters) {
                if (filters[key]) {
                    queryParams.append(key, filters[key]);
                }
            }
            if (byUser) {
                queryParams.append('byUser', 'true');
            }
        }

        addCustomQueryParams();

        getBudgets(fetchData, queryParams)
            .then(data => {
                setBudgets(data.content);
                groupBudgets(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                unblockClicks();
                setLoading(false);
            });
    }, [byUser, pageInfo.pageSize, groupBudgets, fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchBudgets(0, null);
    }, [fetchBudgets, byUser, groupBudgets, blockClicks, unblockClicks]);

    const handlePageChange = (newPage) => {
        setPageInfo((p) => ({
            ...p,
            pageNumber: newPage,
        }));
        fetchBudgets(newPage, filterParams);
    };

    const handleConfirmDuplicate = async (budgetId, assignedUserId, expedientId) => {
        const confirmDuplicate = window.confirm('¿Estás seguro de que quieres duplicar este presupuesto?');

        if (confirmDuplicate) {
            blockClicks();
            await duplicateBudget(fetchData, budgetId, assignedUserId, expedientId)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    }

    const remove = async (id) => {
        const confirmDelete = window.confirm('¿Estás seguro de que quieres eliminar este presupuesto?');

        if (confirmDelete) {
            blockClicks();
            await deleteBudget(fetchData, id)
                .then(() => {
                    let updatedBudgets = [...budgets].filter(i => i.id !== id);
                    setBudgets(updatedBudgets);
                    groupBudgets(updatedBudgets);
                    unblockClicks();
                });
        }
    }

    if (loading) {
        return <Spinner/>;
    }

    const handleRowClick = (travelcatId) => {
        setExpandedTravelcatId(travelcatId === expandedTravelcatId ? null : travelcatId);
        if (!expandedTravelcatId) {
            setOpenDropdownId(null);
        }
    };

    const handleApplyFilters = () => {
        setLoading(true);
        fetchBudgets(0, filterParams);
    };

    const handleClearFilters = () => {
        setLoading(true);
        setFilterParams(initialFilterParams)
        fetchBudgets(0, null);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleApplyFilters();
        }
    };

    const handleToggleDropdown = (budgetId) => {
        if (openDropdownId === budgetId) {
            setOpenDropdownId(null);
        } else {
            setOpenDropdownId(budgetId);
        }
    };

    const handleOpenAssignUserModal = (budgetId, travelcatId) => {
        blockClicks();
        setAssignUserBudgetId(budgetId);
        setAssignUserExpedientTravelcatId(travelcatId);
        setIsAssignUserModalOpen(true);
        unblockClicks();
    };

    const handleCloseAssignUserModal = () => {
        blockClicks();
        setInputAssignedUserId('');
        setAssignUserBudgetId(null);
        setAssignUserExpedientTravelcatId('');
        setIsAssignUserModalOpen(false);
        unblockClicks();
    };

    const handleOpenAssignSellerUserModal = (expedient) => {
        blockClicks();
        setAssignSellerUserExpedientId(expedient.id);
        setAssignSellerUserExpedientTravelcatId(expedient.travelcatId);
        setIsAssignSellerUserModalOpen(true);
        unblockClicks();
    };

    const handleCloseAssignSellerUserModal = () => {
        blockClicks();
        setInputAssignedSellerUserId('');
        setAssignSellerUserExpedientId(null);
        setAssignSellerUserExpedientTravelcatId('');
        setIsAssignSellerUserModalOpen(false);
        unblockClicks();
    };

    const handleOpenAssignPlannerUserModal = (expedient) => {
        blockClicks();
        setAssignPlannerUserExpedientId(expedient.id);
        setAssignPlannerUserExpedientTravelcatId(expedient.travelcatId);
        setIsAssignPlannerUserModalOpen(true);
        unblockClicks();
    };

    const handleCloseAssignPlannerUserModal = () => {
        blockClicks();
        setInputAssignedPlannerUserId('');
        setAssignPlannerUserExpedientId(null);
        setAssignPlannerUserExpedientTravelcatId('');
        setIsAssignPlannerUserModalOpen(false);
        unblockClicks();
    };

    const handleAssignUserFormSubmit = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar este presupuesto del expediente ${assignUserExpedientTravelcatId} a ${inputAssignedUserId}?`);

        if (confirmAssign) {
            blockClicks();
            setLoading(true);
            await assignUser(fetchData, assignUserBudgetId, inputAssignedUserId)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    };

    const handleAssignSellerUserFormSubmit = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar como quién ha vendido el expediente ${assignSellerUserExpedientTravelcatId} a ${inputAssignedSellerUserId}?`);

        if (confirmAssign) {
            blockClicks();
            setLoading(true);
            await assignSellerUser(fetchData, assignSellerUserExpedientId, inputAssignedSellerUserId)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    };
    const handleAssignPlannerUserFormSubmit = async () => {
        const confirmAssign = window.confirm(`¿Estás seguro de que quieres asignar como quién va a planificar el expediente ${assignPlannerUserExpedientTravelcatId} a ${inputAssignedPlannerUserId}?`);

        if (confirmAssign) {
            blockClicks();
            setLoading(true);
            await assignPlannerUser(fetchData, assignPlannerUserExpedientId, inputAssignedPlannerUserId)
                .then(() => {
                    unblockClicks();
                    window.location.reload();
                });
        }
    };

    function getAssignUserIdModals() {
        return <>
            <AssignUserIdModal
                isOpen={isAssignUserModalOpen}
                toggle={handleCloseAssignUserModal}
                handleClose={handleCloseAssignUserModal}
                title="Asignar Usuario"
                description={"Asigna un usuario al presupuesto del expediente " + assignUserExpedientTravelcatId}
                inputAssignedUserId={inputAssignedUserId}
                setInputAssignedUserId={setInputAssignedUserId}
                handleAssignUserFormSubmit={handleAssignUserFormSubmit}
            />
            <AssignUserIdModal
                isOpen={isAssignSellerUserModalOpen}
                toggle={handleCloseAssignSellerUserModal}
                handleClose={handleCloseAssignSellerUserModal}
                title="Asignar Usuario que lo ha vendido"
                description={"Asigna un usuario al expediente " + assignSellerUserExpedientTravelcatId}
                inputAssignedUserId={inputAssignedSellerUserId}
                setInputAssignedUserId={setInputAssignedSellerUserId}
                handleAssignUserFormSubmit={handleAssignSellerUserFormSubmit}
            />
            <AssignUserIdModal
                isOpen={isAssignPlannerUserModalOpen}
                toggle={handleCloseAssignPlannerUserModal}
                handleClose={handleCloseAssignPlannerUserModal}
                title="Asignar Usuario que lo planifica"
                description={"Asigna un usuario al expediente " + assignPlannerUserExpedientTravelcatId}
                inputAssignedUserId={inputAssignedPlannerUserId}
                setInputAssignedUserId={setInputAssignedPlannerUserId}
                handleAssignUserFormSubmit={handleAssignPlannerUserFormSubmit}
            />
        </>;
    }

    function getLinkCustomersModal() {
        return <Modal isOpen={showLinkCustomers} toggle={() => handleCloseLinkCustomers()}
                      size="xl">
            <ModalBody>
                <LinkCustomers
                    expedientId={linkCustomersExpedientId}
                    travelcatId={linkCustomersExpedientTravelcatId}
                    initialCustomerIds={linkCustomersExpedientCustomerIds}
                    onClose={() => handleCloseLinkCustomers()}/>
            </ModalBody>
            <ModalFooter>
                <Button color="danger"
                        onClick={() => handleCloseLinkCustomers()}>Cerrar</Button>
            </ModalFooter>
        </Modal>;
    }

    function getDuplicateModal() {
        return <ExpedientModal
            isOpen={isDuplicateModalOpen}
            toggle={handleCloseDuplicateModal}
            onConfirm={handleConfirmDuplicate}
            budgetId={duplicateModalBudgetId}
            defaultAssignedUserId={duplicateModalDefaultAssignedUserId}
            defaultExpedientId={duplicateModalDefaultExpedientId}
            defaultTravelcatId={duplicateModalDefaultTravelcatId}
        />;
    }

    function getExportBudgetBillingModal() {
        return <ExportBillingModal
            isOpen={isExportModalOpen}
            toggle={() => setIsExportModalOpen(!isExportModalOpen)}
            handleClose={() => setIsExportModalOpen(false)}
            fetchBlob={fetchBlob}
            blockClicks={blockClicks}
            unblockClicks={unblockClicks}
        />;
    }

    const handleShowLinkCustomers = (expedient) => {
        setLinkCustomersExpedientId(expedient.id);
        setLinkCustomersExpedientTravelcatId(expedient.travelcatId);
        setLinkCustomersExpedientCustomerIds(expedient.customers?.map(customer => customer.id) || []);
        setShowLinkCustomers(true);
    };

    const handleCloseLinkCustomers = () => {
        setLinkCustomersExpedientId(null);
        setLinkCustomersExpedientTravelcatId(null);
        setLinkCustomersExpedientCustomerIds(null);
        setShowLinkCustomers(false);
    };

    const handleOpenDuplicateModal = (budget) => {
        setDuplicateModalBudgetId(budget?.id);
        setDuplicateModalDefaultAssignedUserId(budget?.assignedUserId);
        setDuplicateModalDefaultExpedientId(budget?.expedient.id);
        setDuplicateModalDefaultTravelcatId(budget?.expedient.travelcatId);
        setIsDuplicateModalOpen(true);
    };
    const handleCloseDuplicateModal = () => {
        setDuplicateModalBudgetId(null);
        setDuplicateModalDefaultAssignedUserId(null);
        setDuplicateModalDefaultExpedientId(null);
        setDuplicateModalDefaultTravelcatId(null);
        setIsDuplicateModalOpen(false);
    };

    function getDeliveryDateBackgroundColorIfNotTodayOrTomorrow(date) {
        return isPastDate(date) ? '#fef4f0' : '#fce4d6';
    }

    function getDeliveryDateBackgroundColor(date) {
        return isTodayOrTomorrow(date) ? 'red'
            : getDeliveryDateBackgroundColorIfNotTodayOrTomorrow(date);
    }

    function getDeliveryDateFontWeight(date) {
        return isTodayOrTomorrow(date) ? 'bold' : 'normal';
    }

    function getDeliveryDateFontSize(date) {
        return isTodayOrTomorrow(date) ? 'larger' : 'inherit';
    }

    const budgetList = Object.entries(groupedBudgets).map(([travelcatId, budgets]) => {
        const titles = budgets.map(budget => budget.title).join(', ');
        const isExpandable = budgets.length > 1;

        return (
            <React.Fragment key={travelcatId}>
                <tr onClick={() => isExpandable && handleRowClick(travelcatId)}>
                    <td>
                        {isExpandable && (
                            <button
                                className="expand-button"
                                aria-label={expandedTravelcatId ? 'Collapse' : 'Expand'}
                                tabIndex="0"
                                style={{color: expandedTravelcatId === travelcatId ? 'blue' : 'black'}}
                            >
                                {expandedTravelcatId === travelcatId ? '-' : '+'}
                            </button>
                        )}
                        {isExpandable ? travelcatId : (
                            <Link to={`/budgets/${budgets[0].id}/details`}>
                                {travelcatId}
                            </Link>
                        )}
                    </td>
                    <td>{titles}</td>
                    <td>{getTripTypeDictionary()[budgets[0].tripType]}</td>
                    <td style={{
                        backgroundColor: getDeliveryDateBackgroundColor(budgets[0].deliveryDate),
                        fontWeight: getDeliveryDateFontWeight(budgets[0].deliveryDate),
                        fontSize: getDeliveryDateFontSize(budgets[0].deliveryDate)
                    }}>{formatTimestampToDate(budgets[0].deliveryDate)}</td>
                    <td style={{
                        backgroundColor: '#d9eaf7',
                        fontWeight: 'bold'
                    }}>{formatTimestampToDate(budgets[0].departureDate)}</td>
                    <td style={getBudgetStatusStyle(budgets[0].status)}>{getBudgetStatus()[budgets[0].status]}</td>
                    <td>{budgets[0].customers}</td>
                    <td>
                        {budgets[0].expedient?.assignedUserId?.split(',').map((id, index, array) => (
                            <React.Fragment key={index}>
                                {id}
                                {index < array.length - 1 && <br/>}
                            </React.Fragment>
                        ))}
                    </td>
                    <td>{budgets[0].expedient.sellerUserId}</td>
                    <td>{budgets[0].expedient.plannerUserId}</td>
                    {showLastUpdatedFields && (
                        <>
                            <td>{budgets[budgets.length - 1].createdBy}</td>
                            <td>{formatTimestampToDateTime(budgets[budgets.length - 1].creationTimestamp)}</td>
                            <td>{budgets[0].lastUpdatedByAll}</td>
                            <td>{formatTimestampToDateTime(budgets[0].lastUpdatedTimestampAll)}</td>
                        </>
                    )}
                    <td>
                        {(!isExpandable && (
                            <div>
                                <Dropdown isOpen={openDropdownId === budgets[0].id}
                                          toggle={() => handleToggleDropdown(budgets[0].id)}>
                                    <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem tag={Link} to={`/plannings/${budgets[0].expedient.id}`}
                                                      state={{from: location.pathname}}>
                                            Planificación
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => handleOpenAssignUserModal(budgets[0].id, budgets[0].expedient.travelcatId)}>
                                            Asignar usuario
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => handleOpenAssignSellerUserModal(budgets[0].expedient)}>
                                            Asignar usuario que lo ha vendido
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => handleOpenAssignPlannerUserModal(budgets[0].expedient)}>
                                            Asignar usuario que lo planifica
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleShowLinkCustomers(budgets[0].expedient)}>
                                            Vincular clientes
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={`/budgets/${budgets[0].id}`}
                                                      state={{from: location.pathname}}>
                                            Editar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => handleOpenDuplicateModal(budgets[0])}>
                                            Duplicar
                                        </DropdownItem>
                                        <DropdownItem onClick={() => remove(budgets[0].id)}>
                                            Eliminar
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </div>
                        )) || (
                            <Dropdown
                                isOpen={!expandedTravelcatId && openDropdownId === budgets[0].id}
                                toggle={() => handleToggleDropdown(budgets[0].id)}>
                                <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to={`/plannings/${budgets[0].expedient.id}`}
                                                  state={{from: location.pathname}}>
                                        Planificación
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOpenAssignSellerUserModal(budgets[0].expedient)}>
                                        Asignar usuario que lo ha vendido
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOpenAssignPlannerUserModal(budgets[0].expedient)}>
                                        Asignar usuario que lo planifica
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleShowLinkCustomers(budgets[0].expedient)}>
                                        Vincular clientes
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        )}
                    </td>
                </tr>
                {expandedTravelcatId === travelcatId && budgets.map(budget => (
                    <tr key={budget.id}>
                        <td>
                            <Link to={`/budgets/${budget.id}/details`} style={{paddingLeft: '10px'}}>
                                {travelcatId}
                            </Link>
                        </td>
                        <td>{budget.title}</td>
                        <td>{getTripTypeDictionary()[budget.tripType]}</td>
                        <td style={{
                            backgroundColor: getDeliveryDateBackgroundColor(budget.deliveryDate),
                            fontWeight: getDeliveryDateFontWeight(budget.deliveryDate),
                            fontSize: getDeliveryDateFontSize(budget.deliveryDate)
                        }}>{formatTimestampToDate(budget.deliveryDate)}</td>
                        <td style={{
                            backgroundColor: '#d9eaf7',
                            fontWeight: 'bold'
                        }}>{formatTimestampToDate(budget.departureDate)}</td>
                        <td style={getBudgetStatusStyle(budget.status)}>{getBudgetStatus()[budget.status]}</td>
                        <td>{budget.customers}</td>
                        <td>{budget.assignedUserId}</td>
                        <td>{budget.expedient.sellerUserId}</td>
                        <td>{budget.expedient.plannerUserId}</td>
                        {showLastUpdatedFields && (
                            <>
                                <td>{budget.createdBy}</td>
                                <td>{formatTimestampToDateTime(budget.creationTimestamp)}</td>
                                <td>{budget.lastUpdatedByAll}</td>
                                <td>{formatTimestampToDateTime(budget.lastUpdatedTimestampAll)}</td>
                            </>
                        )}
                        <td>
                            <Dropdown
                                isOpen={openDropdownId === budget.id}
                                toggle={() => handleToggleDropdown(budget.id)}>
                                <DropdownToggle caret size="sm" color="warning">Más</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem tag={Link} to={`/budgets/${budget.id}`}
                                                  state={{from: location.pathname}}>
                                        Editar
                                    </DropdownItem>
                                    <DropdownItem onClick={() => handleOpenDuplicateModal(budget)}>
                                        Duplicar
                                    </DropdownItem>
                                    <DropdownItem
                                        onClick={() => handleOpenAssignUserModal(budget.id, budget.expedient.travelcatId)}>
                                        Asignar usuario
                                    </DropdownItem>
                                    <DropdownItem onClick={() => remove(budget.id)}>
                                        Eliminar
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </td>
                    </tr>
                ))}
            </React.Fragment>
        );
    });

    const handleCheckboxChange = () => {
        setPageInfo(defaultPageParams);
        setFilterParams(initialFilterParams);
        setByUser(!byUser);
    };

    function handleShowLastUpdatedFieldsCheckboxChange() {
        setLoading(true);

        const newShowLastUpdatedFields = !showLastUpdatedFields;
        setShowLastUpdatedFields(newShowLastUpdatedFields);

        const updatedFilterParams = {
            ...filterParams,
            showLastUpdatedFields: newShowLastUpdatedFields
        };

        fetchBudgets(pageInfo.pageNumber, updatedFilterParams);
    }

    return (
        <div>
            <ClickBlockOverlay/>
            <Container fluid>
                <div className="d-flex justify-content-between align-items-center">
                    <h3>Expedientes</h3>
                    <div className="d-flex">
                        <Input
                            type="checkbox"
                            id="byUserCheckbox"
                            checked={byUser}
                            onChange={handleCheckboxChange}
                        />
                        <Label for="byUserCheckbox" className="ms-2">
                            Mis presupuestos
                        </Label>
                    </div>
                    <FormGroup className="d-flex">
                        <Input
                            type="text"
                            placeholder="Expediente"
                            value={filterParams.travelcatId}
                            style={{
                                width: '100px',
                                flexBasis: `${Math.max(filterParams.travelcatId.length, 10)}ch`
                            }}
                            onChange={(e) => setFilterParams(prevFilterParams => ({
                                ...prevFilterParams,
                                travelcatId: e.target.value
                            }))}
                            autoComplete="travelcatId"
                            onKeyDown={handleKeyDown}
                        />
                        <Input
                            type="text"
                            placeholder="Destino"
                            value={filterParams.title}
                            style={{width: '100px', flexBasis: `${Math.max(filterParams.title.length, 10)}ch`}}
                            onChange={(e) => setFilterParams(prevFilterParams => ({
                                ...prevFilterParams,
                                title: e.target.value
                            }))}
                            autoComplete="title"
                            onKeyDown={handleKeyDown}
                        />
                        <Input
                            type="select"
                            name="tripType"
                            id="tripType"
                            value={filterParams.tripType}
                            onChange={(e) => setFilterParams(prevFilterParams => ({
                                ...prevFilterParams,
                                tripType: e.target.value
                            }))}
                            style={{width: '100px', flexBasis: `${Math.max(filterParams.tripType.length, 10)}ch`}}
                        >
                            <option value="">Tipo</option>
                            {Object.entries(getTripTypeDictionary()).map(([key, value]) => (
                                <option key={key} value={key}>
                                    {value}
                                </option>
                            ))}
                        </Input>
                        <Input
                            type="select"
                            name="status"
                            id="status"
                            value={filterParams.status}
                            onChange={(e) => setFilterParams(prevFilterParams => ({
                                ...prevFilterParams,
                                status: e.target.value
                            }))}
                            style={{width: '100px', flexBasis: `${Math.max(filterParams.status.length, 10)}ch`}}
                        >
                            <option value="">Estado</option>
                            {Object.entries(getBudgetStatus()).map(([key, value]) => (
                                <option key={key} value={key} style={getBudgetStatusStyle(key)}>
                                    {value}
                                </option>
                            ))}
                        </Input>
                        <Input
                            type="text"
                            placeholder="Cliente(s)"
                            value={filterParams.customers}
                            style={{
                                width: '100px',
                                flexBasis: `${Math.max(filterParams.customers.length, 10)}ch`
                            }}
                            onChange={(e) => setFilterParams(prevFilterParams => ({
                                ...prevFilterParams,
                                customers: e.target.value
                            }))}
                            autoComplete="customers"
                            onKeyDown={handleKeyDown}
                        />
                        <ButtonGroup>
                            <Button color="warning" onClick={() => handleApplyFilters()}>Aplicar filtros</Button>
                            <Button color="secondary" onClick={() => handleClearFilters()}>Reiniciar</Button>
                        </ButtonGroup>
                    </FormGroup>
                    <div className="d-flex">
                        <ButtonGroup>
                            <Button color="success" onClick={() => setIsExportModalOpen(true)}>Facturación</Button>
                            <Button color="primary" tag={Link} to="/budgets/new" state={{from: location.pathname}}>Añadir
                                presupuesto</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <br/>
                <div className="d-flex align-content-center float-end">
                    <Input
                        type="checkbox"
                        id="showLastUpdatedFieldsCheckbox"
                        checked={showLastUpdatedFields}
                        onChange={handleShowLastUpdatedFieldsCheckboxChange}
                    />
                    <Label for="showLastUpdatedFieldsCheckbox" className="ms-2">
                        Ver campos de creación y modificación
                    </Label>
                </div>
                <Table className="mt-4 table-hover">
                    <thead>
                    <tr>
                        <th width="8%">Expediente</th>
                        <th width="10%">Destino</th>
                        <th width="8%">Tipo de viaje</th>
                        <th width="8%" style={{backgroundColor: '#fef4f0'}}>Fecha de entrega</th>
                        <th width="8%" style={{
                            backgroundColor: '#d9eaf7',
                            fontWeight: 'bold'
                        }}>Fecha de salida
                        </th>
                        <th width="8%">Estado</th>
                        <th width="10%">Cliente(s)</th>
                        <th width="6%">Usuario(s) asignado(s)</th>
                        <th width="6%">Usuario que lo ha vendido</th>
                        <th width="6%">Usuario que lo planifica</th>
                        {showLastUpdatedFields && (
                            <>
                                <th width="4%">Creado por</th>
                                <th width="4%">Creado el</th>
                                <th width="4%">Modificado por</th>
                                <th width="4%">Modificado el</th>
                            </>
                        )}
                        <th width="6%">Acciones</th>
                    </tr>
                    </thead>
                    <tbody>
                    {budgetList}
                    </tbody>
                </Table>
                <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
            </Container>
            {getAssignUserIdModals()}
            {showLinkCustomers && getLinkCustomersModal()}
            {isDuplicateModalOpen && getDuplicateModal()}
            {isExportModalOpen && getExportBudgetBillingModal()}
        </div>
    );
};

export default BudgetList;
