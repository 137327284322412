import React, {useCallback, useEffect, useState} from 'react';
import {Modal, ModalBody, ModalFooter, ModalHeader, Table} from 'reactstrap';
import {useFetchData} from "../../http/HttpUtil";
import PropTypes from "prop-types";
import '../../common/ModalFullScreen.css';
import {formatTimestampToDateTime} from "../../common/Utils";
import PaginationContainer, {defaultPageParams} from "../../common/PaginationContainer";
import {ClickBlockOverlay} from "../../common/ClickBlockOverlay";
import {useClickBlock} from "../../context/ClickBlockContext";
import {getAuditData} from "../../service/AuditRestService";

function AuditResponseWidget({entity, id, onClose}) {
    const fetchData = useFetchData();

    const auditEntities = {
        Customer: {
            name: 'Cliente',
            fields: {
                'firstName.firstSurname': 'Nombre y apellido',
                firstName: 'Nombre',
                firstSurname: 'Primer apellido',
                secondSurname: 'Segundo apellido',
                phone: 'Teléfono',
                email: 'Email',
                age: 'Edad',
                idNumber: 'DNI',
                passport: 'Pasaporte',
                birthDate: 'Fecha de nacimiento',
                address: 'Dirección',
                comments: 'Comentarios',
            }
        },
        Expedient: {
            name: 'Expediente',
            fields: {
                travelcatId: 'Identificador',
                assignedUserId: 'Usuario asignado',
                sellerUserId: 'Usuario que lo ha vendido',
                plannerUserId: 'Usuario que lo planifica',
            }
        },
        CaseOpening: {
            name: 'Apertura de expediente',
            fields: {
                email: 'Email',
                phone: 'Teléfono',
                fromCity: 'Ciudad Origen',
                availability: 'Disponibilidad',
                tripOfInterest: 'Viaje de Interés',
                entrypoint: 'Vía de Entrada',
                comments: 'Comentarios',
            }
        },
        CaseTracking: {
            name: 'Seguimiento Pre-venta',
            fields: {
                'callAndSend.expectedDelivery': 'Ficha de seguimiento pre-venta',
                callAndSend: 'Llamada y envío',
                expectedDelivery: 'Entrega prevista',
                sentToClient: 'Envío cliente',
                email: 'Email',
                whatsApp: 'Whatsapp',
                tracking: 'Seguimiento',
                salesAppointment: 'Cita de venta',
                askAmendments: 'Pedir cambios',
                expectedDeliveryAfterAmendments: 'Entrega prevista',
                updateSentToClient: 'Envío actualización',
                emailAmendment: 'Email planificación',
                whatsAppAmendment: 'Whatsapp planificación',
                bookingEmail: 'Correo reserva',
                openPostSalesFile: 'Abrir ficha post-venta',
            }
        },
        PostSales: {
            name: 'Seguimiento Post-venta',
            fields: {
                'mailReservation.payment': 'Ficha de seguimiento post-venta',
                mailReservation: 'Correo reserva',
                payment: 'Comp. pago',
                idPass: 'DNI/Pasaporte',
                whatsAppReservationConfirmed: 'Whatsapp reserva confirmada',
                itineraryReceiptAgreementEtc: 'Itinerario-Recibo-Contrato-etc',
                schedule: 'Agendar',
                agreementSignature: 'Firma contrato',
                planningAppointment: 'Cita planificación',
                laterChecklist: 'Checklist posterior',
                documentationDelivery: 'Entrega documentación',
                personalDocumentationReview: 'Revisión documentación personal',
                countryRestrictionReview: 'Revisión restricciones país',
                sentWhatsAppRestrictionsLink: 'Enviar Whatsapp enlace restricciones',
                planning: 'Planificación',
                currencyExchange: 'Tramitación moneda',
                networkData: 'Tramitación internet',
                costCalculation: 'Cálculo gastos',
                emergencyPhone: 'Telf. emergencia',
                restaurantsGuidebook: 'Guía restaurantes',
                sendDigitalDocumentation: 'Enviar documentación digital',
                sendFinalPlanningAndActivitiesWithPrices: 'Enviar planificación definitiva + excursiones con precio',
                settleTripPayments: 'Liquidar pagos viaje',
                settleActivitiesVisaEtcPayments: 'Liquidar pagos excursones, visados, etc',
                bonusesDelivery: 'Entrega bonos',
                payEverything: 'Pago',
                refundPaymentServicesAtDestination: 'Rembolsar servicios de pago en destino',
            }
        },
        Budget: {
            name: 'Presupuesto',
            fields: {
                title: 'Destino',
                tripType: 'Tipo de viaje',
                deliveryDate: 'Fecha de entrega',
                status: 'Estado del presupuesto',
                assignedUserId: 'Usuario asignado',
                customers: 'Cliente(s)',
            }
        },
        Incident: {
            name: 'Incidente',
            fields: {
                date: 'Fecha',
                comment: 'Observación / Incidencia',
            }
        },
        Task: {
            name: 'Tarea',
            fields: {
                'taskCategory.status': 'Categoría y estado',
                taskCategory: 'Categoría',
                status: 'Estado',
            }
        },
        Accounting: {
            name: 'Contabilidad',
            fields: {
                price: 'Neto',
                pvp: 'PVP',
                pricePerFamily: 'Precio por familia',
                pricePerFamilyEnabled: 'Precio por familia habilitado',
                total: 'Total',
                benefit: 'Beneficio',
            }
        },
        ActivityAccounting: {
            name: 'Contabilidad de actividades / excursiones',
            fields: {
                price: 'Neto',
                pvp: 'PVP',
                pricePerFamily: 'Precio por familia',
                pricePerFamilyEnabled: 'Precio por familia habilitado',
                total: 'Total',
                benefit: 'Beneficio',
            }
        },
        Flight: {
            name: 'Reserva de vuelo',
            fields: {
                reservationCode: 'Código Reserva',
                reservationUntil: 'Reservado hasta',
                price: 'Precio',
                notes: 'Notas',
                itemStatus: 'Estado',
                excludeFromPrice: 'Alternativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        FlightSplit: {
            name: 'Vuelo',
            fields: {
                date: 'Fecha',
                itinerary: 'Itinerario',
                company: 'Aerolínea',
                supplier: 'Proveedor',
                landNextDay: 'Aterriza siguiente día',
                scheduleChanged: 'Cambio de horario',
                updatedItinerary: 'Nuevo Itinierario',
            }
        },
        Accommodation: {
            name: 'Alojamiento',
            fields: {
                dateTime: 'Fecha de entrada',
                deliveryDateTime: 'Fecha de salida',
                nights: 'Nº Noches',
                regime: 'Régimen',
                room: 'Habitación',
                price: 'Precio',
                supplier: 'Proveedor',
                notes: 'Notas',
                itemStatus: 'Estado',
                excludeFromPrice: 'Alternativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        AccommodationDetail: {
            name: 'Detalle del alojamiento',
            fields: {
                'destination.name': 'Ubucación y alojamiento',
                destination: 'Ubicación',
                name: 'Alojamiento',
                stars: 'Estrellas',
                link: 'Enlace',
            }
        },
        Transfer: {
            name: 'Traslado',
            fields: {
                'origin.destination': 'Detalle',
                dateTime: 'Fecha',
                origin: 'Ubicación recogida',
                destination: 'Ubicación destino',
                price: 'Precio',
                supplier: 'Proveedor',
                notes: 'Notas',
                itemStatus: 'Estado',
                excludeFromPrice: 'Alternativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        Excursion: {
            name: 'Actividad incluida',
            fields: {
                dateTime: 'Día y hora',
                name: 'Excursión o actividad',
                supplier: 'Proveedor',
                notes: 'Notas',
                price: 'Precio (neto)',
                itemStatus: 'Estado',
                excludeFromPrice: 'Atlernativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        CarRent: {
            name: 'Alquiler de coche',
            fields: {
                dateTime: 'Fecha de recogida',
                deliveryDateTime: 'Fecha de devolución',
                origin: 'Recogida',
                destination: 'Devolución',
                carModel: 'Modelo',
                price: 'Precio',
                supplier: 'Proveedor',
                notes: 'Notas',
                itemStatus: 'Estado',
                excludeFromPrice: 'Alternativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        Activity: {
            name: 'Actividad extra',
            fields: {
                dateTime: 'Día y hora',
                name: 'Excursión o actividad',
                supplier: 'Proveedor',
                notes: 'Notas',
                price: 'Neto',
                pvp: 'PVP',
                benefit: 'Beneficio',
                itemStatus: 'Estado',
                excludeFromPrice: 'Atlernativa',
                positionInBudget: 'Posición en presupuesto',
                paymentDateTime: 'Fecha de pago',
            }
        },
        BudgetFinancing: {
            name: 'Financiaciones en presupuesto',
            fields: {
                totalPrice: 'Precio total',
                comments: 'Observaciones',
                schedule: 'Agendar',
                signed: 'Firma contrato',
            }
        },
        Financing: {
            name: 'Financiación',
            fields: {
                position: 'Posición',
                date: 'Fecha',
                total: 'Precio',
                status: 'Pagado',
            }
        },
        BudgetExtra: {
            name: 'Extras en presupuestos',
            fields: {
                forestPrice: 'Bosque',
                insurancePrice: 'Seguro',
                giftPrice: 'Regalo',
                cancellationInsurance: 'Seguro de cancelación',
            }
        },
        Extra: {
            name: 'Extra',
            fields: {
                extraType: 'Tipo',
                price: 'Precio',
            }
        },
        Payment: {
            name: 'Pago',
            fields: {
                'paymentType.total': 'Tipo y total',
                position: 'Número de pago',
                total: 'Total',
                date: 'Fecha de realización',
                paymentType: 'Tipo de pago',
            }
        },
        PaymentSplit: {
            name: 'División de pago',
            fields: {
                'paymentCategory.amount': 'Subtipo y cantidad',
                paymentCategory: 'Categoría de pago',
                amount: 'Cantidad',
            }
        },
        BudgetPeople: {
            name: 'Número de personas',
            fields: {
                numberOfAdults: 'Número de adultos',
                numberOfBabies: 'Número de bebés',
            }
        },
        Kid: {
            name: 'Niño',
            fields: {
                age: 'Edad',
            }
        },
    }

    const auditActions = {
        ADD: 'Añadido',
        MOD: 'Modificado',
        DEL: 'Eliminado',
    }

    const [pageInfo, setPageInfo] = useState(defaultPageParams);
    const [auditResponses, setAuditResponses] = useState([]);
    const {blockClicks, unblockClicks} = useClickBlock();

    const fetchAuditResponses = useCallback(async (pageNumber) => {
        blockClicks();
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageInfo.pageSize);
        await getAuditData(fetchData, entity, id, queryParams)
            .then(data => {
                setAuditResponses(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                unblockClicks();
            });
    }, [entity, id, pageInfo.pageSize, fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchAuditResponses(0).then();
    }, [fetchAuditResponses, blockClicks, unblockClicks]);

    const handlePageChange = (pageNumber) => {
        fetchAuditResponses(pageNumber).then();
    };

    return (
        <div>
            <ClickBlockOverlay/>
            <Modal isOpen={true} toggle={onClose} className="modal-fullscreen">
                <ModalHeader toggle={onClose}>Registros</ModalHeader>
                <ModalBody>
                    <Table className="table table-striped table-hover">
                        <thead>
                        <tr>
                            <th>Fecha y hora</th>
                            <th>Usuario</th>
                            <th>Acción</th>
                            <th>Entidad</th>
                            <th>Campo</th>
                            <th>Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        {auditResponses?.map((response) => (
                            <tr key={response.revisionId + response.entity + response.entityId + response.field}>
                                <td>{formatTimestampToDateTime(response.dateTime)}</td>
                                <td>{response.user}</td>
                                <td>{auditActions[response.action] || response.action}</td>
                                <td>{auditEntities[response.entity]?.name || response.entity}</td>
                                <td>{auditEntities[response.entity]?.fields[response.field] || response.field}</td>
                                <td>{auditEntities[response.entity] === auditEntities.PostSales &&
                                auditActions[response.action] === auditActions.ADD ?
                                    '' :
                                    response.value}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </ModalBody>
                <ModalFooter>
                    <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
                </ModalFooter>
            </Modal>
        </div>
    );
}

AuditResponseWidget.propTypes = {
    entity: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired
};

export default AuditResponseWidget;
