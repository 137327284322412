import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonGroup, Container, Input, Label, Table} from 'reactstrap';
import {useFetchData} from "../http/HttpUtil";
import './Planning.css';
import '../common/Common.css';
import Spinner from "../common/Spinner";
import {Link, useLocation} from "react-router-dom";
import PaginationContainer, {defaultPageParams, defaultPageParamsSizeTen} from "../common/PaginationContainer";
import {ClickBlockOverlay} from "../common/ClickBlockOverlay";
import {useClickBlock} from "../context/ClickBlockContext";
import {getExpedients, updateExpedient} from "../service/ExpedientRestService";

const PlanningList = () => {
    const fetchData = useFetchData();
    const location = useLocation();
    const [expedients, setExpedients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [byUser, setByUser] = useState(true);
    const [searchTravelcatId, setSearchTravelcatId] = useState('');
    const [pageInfo, setPageInfo] = useState(defaultPageParamsSizeTen);
    const [showCaseTracking, setShowCaseTracking] = useState(false);
    const {blockClicks, unblockClicks} = useClickBlock();
    const initialPageNumber = defaultPageParamsSizeTen.pageNumber;
    const pageSize = defaultPageParamsSizeTen.pageSize;

    const fetchExpedients = useCallback((pageNumber, pageSize, searchText, byUserCheckbox) => {
        setLoading(true);
        blockClicks();
        const queryParams = new URLSearchParams();
        queryParams.append('page', pageNumber);
        queryParams.append('size', pageSize);
        if (byUserCheckbox) {
            queryParams.append('byUser', 'true');
        }
        if (searchText) {
            queryParams.append('travelcatId', searchText);
        }

        getExpedients(fetchData, queryParams)
            .then(data => {
                setExpedients(data.content);
                setPageInfo((p) => ({
                    ...p,
                    pageNumber: data.pageable.pageNumber,
                    pageSize: data.pageable.pageSize,
                    totalPages: data.totalPages,
                    totalElements: data.totalElements,
                }));
                unblockClicks();
                setLoading(false);
            });
    }, [fetchData, blockClicks, unblockClicks]);

    useEffect(() => {
        fetchExpedients(initialPageNumber, pageSize, null, true);
    }, [fetchExpedients, blockClicks, unblockClicks, initialPageNumber, pageSize]);

    const handlePageChange = (newPage) => {
        setPageInfo((p) => ({
            ...p,
            pageNumber: newPage,
        }));
        fetchExpedients(newPage, pageSize, searchTravelcatId, byUser);
    };

    const handleCheckboxChange = (check) => {
        setPageInfo(defaultPageParams);
        setByUser(check);
        fetchExpedients(initialPageNumber, pageSize, searchTravelcatId, check);
    };

    const handleSearchInputChange = (event) => {
        setSearchTravelcatId(event.target.value);
    };

    const handleApplySearch = () => {
        setLoading(true);
        fetchExpedients(initialPageNumber, pageSize, searchTravelcatId, byUser);
    };

    const handleClearFilters = () => {
        setLoading(true);
        setSearchTravelcatId('')
        fetchExpedients(initialPageNumber, pageSize, '', byUser);
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleApplySearch();
        }
    };

    const handleToggleShowCaseTracking = () => {
        setShowCaseTracking(!showCaseTracking);
    };

    if (loading) {
        return <Spinner/>;
    }

    const handleChange = (expedient, parentFieldName, fieldName, value) => {
        blockClicks();
        expedient[parentFieldName][fieldName] = value;
        updateExpedient(fetchData, expedient)
            .then(() => {
                unblockClicks();
                fetchExpedients(pageInfo.pageNumber, pageInfo.pageSize, searchTravelcatId, byUser);
            });
    };

    const planningList = expedients?.map(expedient => (
        <tr key={expedient.id}>
            <td className="expedient-column">
                <Link to={`/plannings/${expedient.id}`} state={{from: location.pathname}}>
                    {expedient.travelcatId}
                </Link>
            </td>
            {showCaseTracking && (
                <>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.callAndSend}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'callAndSend', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.expectedDelivery}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'expectedDelivery', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.sentToClient}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'sentToClient', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.email}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'email', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.whatsApp}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'whatsApp', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.tracking}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'tracking', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.salesAppointment}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'salesAppointment', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.askAmendments}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'askAmendments', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.expectedDeliveryAfterAmendments}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'expectedDeliveryAfterAmendments', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="date"
                            value={expedient.caseTracking.updateSentToClient}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'updateSentToClient', e.target.value)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.emailAmendment}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'emailAmendment', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.whatsAppAmendment}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'whatsAppAmendment', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.bookingEmail}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'bookingEmail', e.target.checked)}
                        />
                    </td>
                    <td className="case-tracking-column">
                        <input
                            type="checkbox"
                            checked={expedient.caseTracking.openPostSalesFile}
                            onChange={(e) => handleChange(expedient, 'caseTracking', 'openPostSalesFile', e.target.checked)}
                        />
                    </td>
                </>
            )}
            {!showCaseTracking && (
                <>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.mailReservation}
                            onChange={(e) => handleChange(expedient, 'postSales', 'mailReservation', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.payment}
                            onChange={(e) => handleChange(expedient, 'postSales', 'payment', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.idPass}
                            onChange={(e) => handleChange(expedient, 'postSales', 'idPass', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.whatsAppReservationConfirmed}
                            onChange={(e) => handleChange(expedient, 'postSales', 'whatsAppReservationConfirmed', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.itineraryReceiptAgreementEtc}
                            onChange={(e) => handleChange(expedient, 'postSales', 'itineraryReceiptAgreementEtc', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.schedule}
                            onChange={(e) => handleChange(expedient, 'postSales', 'schedule', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.agreementSignature}
                            onChange={(e) => handleChange(expedient, 'postSales', 'agreementSignature', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.planningAppointment}
                            onChange={(e) => handleChange(expedient, 'postSales', 'planningAppointment', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.laterChecklist}
                            onChange={(e) => handleChange(expedient, 'postSales', 'laterChecklist', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.documentationDelivery}
                            onChange={(e) => handleChange(expedient, 'postSales', 'documentationDelivery', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.personalDocumentationReview}
                            onChange={(e) => handleChange(expedient, 'postSales', 'personalDocumentationReview', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.countryRestrictionReview}
                            onChange={(e) => handleChange(expedient, 'postSales', 'countryRestrictionReview', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.sentWhatsAppRestrictionsLink}
                            onChange={(e) => handleChange(expedient, 'postSales', 'sentWhatsAppRestrictionsLink', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.planning}
                            onChange={(e) => handleChange(expedient, 'postSales', 'planning', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.currencyExchange}
                            onChange={(e) => handleChange(expedient, 'postSales', 'currencyExchange', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.networkData}
                            onChange={(e) => handleChange(expedient, 'postSales', 'networkData', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.costCalculation}
                            onChange={(e) => handleChange(expedient, 'postSales', 'costCalculation', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.emergencyPhone}
                            onChange={(e) => handleChange(expedient, 'postSales', 'emergencyPhone', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.restaurantsGuidebook}
                            onChange={(e) => handleChange(expedient, 'postSales', 'restaurantsGuidebook', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.sendDigitalDocumentation}
                            onChange={(e) => handleChange(expedient, 'postSales', 'sendDigitalDocumentation', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.sendFinalPlanningAndActivitiesWithPrices}
                            onChange={(e) => handleChange(expedient, 'postSales', 'sendFinalPlanningAndActivitiesWithPrices', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.settleTripPayments}
                            onChange={(e) => handleChange(expedient, 'postSales', 'settleTripPayments', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.settleActivitiesVisaEtcPayments}
                            onChange={(e) => handleChange(expedient, 'postSales', 'settleActivitiesVisaEtcPayments', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.bonusesDelivery}
                            onChange={(e) => handleChange(expedient, 'postSales', 'bonusesDelivery', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.payEverything}
                            onChange={(e) => handleChange(expedient, 'postSales', 'payEverything', e.target.checked)}
                        />
                    </td>
                    <td className="post-sales-column">
                        <input
                            type="checkbox"
                            checked={expedient.postSales.refundPaymentServicesAtDestination}
                            onChange={(e) => handleChange(expedient, 'postSales', 'refundPaymentServicesAtDestination', e.target.checked)}
                        />
                    </td>
                </>
            )}
        </tr>
    ));

    return (
        <div>
            <ClickBlockOverlay/>
            <Container fluid>
                <div className="d-flex justify-content-between align-items-center">
                    <h3>Planificación</h3>
                    <div className="col d-flex justify-content-center">
                        <Input
                            type="checkbox"
                            id="byUserCheckbox"
                            checked={byUser}
                            onChange={() => handleCheckboxChange(!byUser)}
                        />
                        <Label for="byUserCheckbox" className="ms-2">
                            Mis expedientes
                        </Label>
                    </div>
                    <div className="col d-flex justify-content-center">
                        <Button color="success" size="sm" onClick={handleToggleShowCaseTracking}>
                            {showCaseTracking ? 'Mostrar Post-Venta' : 'Mostrar Pre-Venta'}
                        </Button>
                    </div>
                    <div className="d-flex align-items-center">
                        <input
                            type="text"
                            className="form-control me-2"
                            placeholder="Buscar expediente"
                            value={searchTravelcatId}
                            size="sm"
                            onChange={handleSearchInputChange}
                            onKeyDown={handleKeyDown}
                        />
                        <ButtonGroup>
                            <Button color="warning" size="sm" onClick={handleApplySearch}>Aplicar</Button>
                            <Button color="secondary" size="sm" onClick={handleClearFilters}>Reiniciar</Button>
                        </ButtonGroup>
                    </div>
                </div>
                <div className="table-container">
                    <Table className="mt-4 table-striped table-hover">
                        <thead>
                        <tr>
                            <th rowSpan="2" className="expedient-header">Expediente</th>
                            {showCaseTracking ? (
                                <th colSpan="14" className="case-tracking-header">PRE-VENTA</th>
                            ) : (
                                <th colSpan="26" className="post-sales-header">POST-VENTA</th>
                            )}
                        </tr>
                        <tr>
                            {showCaseTracking && (
                                <>
                                    <th className="case-tracking-column">Llamada y envío</th>
                                    <th className="case-tracking-column">Entrega prevista</th>
                                    <th className="case-tracking-column">Envío cliente</th>
                                    <th className="case-tracking-column">Email</th>
                                    <th className="case-tracking-column">Whatsapp</th>
                                    <th className="case-tracking-column">Seguimiento</th>
                                    <th className="case-tracking-column">Cita de venta</th>
                                    <th className="case-tracking-column">Pedir cambios</th>
                                    <th className="case-tracking-column">Entrega prevista</th>
                                    <th className="case-tracking-column">Envío actualización</th>
                                    <th className="case-tracking-column">Email planificación</th>
                                    <th className="case-tracking-column">Whatsapp planificación</th>
                                    <th className="case-tracking-column">Correo reserva</th>
                                    <th className="case-tracking-column">Abrir ficha post-venta</th>
                                </>
                            )}
                            {!showCaseTracking && (
                                <>
                                    <th className="post-sales-column">Correo reserva</th>
                                    <th className="post-sales-column">Comp. pago</th>
                                    <th className="post-sales-column">DNI/Pasaporte</th>
                                    <th className="post-sales-column">Whatsapp reserva confirmada</th>
                                    <th className="post-sales-column">Itinerario-Recibo-Contrato-etc</th>
                                    <th className="post-sales-column">Agendar</th>
                                    <th className="post-sales-column">Firma contrato</th>
                                    <th className="post-sales-column">Cita planificación</th>
                                    <th className="post-sales-column">Checklist posterior</th>
                                    <th className="post-sales-column">Entrega documentación</th>
                                    <th className="post-sales-column">Revisión documentación personal</th>
                                    <th className="post-sales-column">Revisión restricciones país</th>
                                    <th className="post-sales-column">Enviar Whatsapp enlace restricciones</th>
                                    <th className="post-sales-column">Planificación</th>
                                    <th className="post-sales-column">Tramitación moneda</th>
                                    <th className="post-sales-column">Tramitación internet</th>
                                    <th className="post-sales-column">Cálculo gastos</th>
                                    <th className="post-sales-column">Telf. emergencia</th>
                                    <th className="post-sales-column">Guía restaurantes</th>
                                    <th className="post-sales-column">Enviar documentación digital</th>
                                    <th className="post-sales-column">Enviar planificación definitiva + excursiones con
                                        precio
                                    </th>
                                    <th className="post-sales-column">Liquidar pagos viaje</th>
                                    <th className="post-sales-column">Liquidar pagos excursones, visados, etc</th>
                                    <th className="post-sales-column">Entrega bonos</th>
                                    <th className="post-sales-column">Pago</th>
                                    <th className="post-sales-column">Rembolsar servicios de pago en destino</th>
                                </>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {planningList}
                        </tbody>
                    </Table>
                </div>
                <PaginationContainer pageInfo={pageInfo} handlePageChange={handlePageChange}/>
            </Container>
        </div>
    );
};

export default PlanningList;
