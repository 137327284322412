import {BASE_API_URL} from "./RestService";

export function getBudgetDetail(fetchData, id) {
    return fetchData(`${BASE_API_URL}/budget/${id}`, {});
}

export function updateBudgetDetailByJson(fetchData, jsonBody) {
    return fetchData(`${BASE_API_URL}/budget`, {
        method: 'PUT',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: jsonBody,
    });
}

export function updateBudgetDetail(fetchData, requestBody) {
    return updateBudgetDetailByJson(fetchData, JSON.stringify(requestBody));
}

export function duplicateBudgetDetail(fetchData, budgetDetailEndpoint, id) {
    return fetchData(`${BASE_API_URL}/${budgetDetailEndpoint}/duplicate/${id}`, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function deleteBudgetDetail(fetchData, budgetDetailEndpoint, id) {
    return fetchData(`${BASE_API_URL}/${budgetDetailEndpoint}/${id}`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        }
    });
}

export function deleteTask(fetchData, taskId) {
    return deleteBudgetDetail(fetchData, 'task', taskId);
}

function updateDetail(fetchData, budgetDetailEndpoint, data) {
    return fetchData(`${BASE_API_URL}/${budgetDetailEndpoint}`, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export function updateFinancing(fetchData, financingPayment) {
    return updateDetail(fetchData, 'financing', financingPayment);
}

export function updateTask(fetchData, task) {
    return updateDetail(fetchData, 'task', task);
}

export function deleteBudgetDetails(fetchData, id, data) {
    return fetchData(`${BASE_API_URL}/budget/${id}/details`, {
        method: 'DELETE',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}
